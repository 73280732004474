.pvyzy55{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;background:#F2F3F7;padding-bottom:31px;}@media (min-width:1024px){.pvyzy55{padding-bottom:96px;}}
.h10l9vaj{grid-column:4/13;}@media (max-width:1439px){.h10l9vaj{grid-column:5/13;}}@media (max-width:1024px){.h10l9vaj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;}}
.t14jyud8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:32px;margin-bottom:64px;}@media (max-width:1279px){.t14jyud8{gap:16px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}@media (max-width:768px){.t14jyud8{margin-bottom:40px;}}
.sqrxi04{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;grid-column:1/4;margin-bottom:64px;}@media (max-width:1279px){.sqrxi04{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}}@media (max-width:1023px){.sqrxi04{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}@media (max-width:600px){.sqrxi04{margin-bottom:40px;}}
.c1vdx5qw{background-color:white;border-radius:24px;padding:24px;}
.t1j8r0q6{margin-bottom:40px;}@media (max-width:768px){.t1j8r0q6{margin-bottom:32px;}}
.t170bn2e{margin-bottom:40px;}@media (max-width:1279px){.t170bn2e{display:none;}}@media (max-width:1280px){.t170bn2e{margin-bottom:32px;}}
.t5evcgv{display:none;margin-bottom:32px;}@media (max-width:1279px){.t5evcgv{display:block;}}@media (max-width:600px){.t5evcgv{margin-bottom:32px;}}
.ix2xop2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background-color:#f2f3f7;border-radius:50%;width:52px;height:52px;min-width:52px;}
.cq4dbju > span > div{font-weight:700;}
.hg9agcm{display:grid;grid-template-columns:repeat(12,1fr);grid-column-gap:52px;max-width:100vw;grid-column:1/4;}@media (max-width:1279px){.hg9agcm{display:block;}}
.ceprv2x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;grid-column:4/13;}
.lrsya0i{-webkit-text-decoration:none;text-decoration:none;color:inherit;}
