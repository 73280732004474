.a3xa39k{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.a3xa39k > :first-child{margin-bottom:8px;}@media (min-width:768px){.a3xa39k{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding-right:64px;}.a3xa39k > :first-child{margin-bottom:0px;}}
.f19lsimg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:8px;}
.ptzfvo4 > :first-child{margin-bottom:8px;}.ptzfvo4 > :nth-child(2){margin-bottom:8px;}@media (min-width:768px){.ptzfvo4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:8px;}.ptzfvo4 > :first-child{margin-bottom:0px;}.ptzfvo4 > :nth-child(2n){margin-bottom:0px;}}
.a1galzgn{margin-bottom:40px;margin-top:24px;}.a1galzgn > :nth-child(2n + 1){margin-top:24px;}@media (min-width:768px){.a1galzgn{display:grid;grid-column-gap:40px;grid-row-gap:24px;}.a1galzgn > :nth-child(2n + 1){margin-top:0px;}}
.c1nynl0b{font-weight:500 !important;-webkit-text-decoration:var(--c1nynl0b-0);text-decoration:var(--c1nynl0b-0);}
.pz7wzos{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:8px;}
.pxtj1es{outline:var(--pxtj1es-0);width:104px;height:108px;border-radius:12px;cursor:pointer;}
.f96xbnj{max-width:100%;border-radius:16px;height:auto;}
.soe8b5z > :nth-child(2){width:100% !important;height:100% !important;background-color:unset;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.soe8b5z > :nth-child(2) > :nth-child(2){width:100% !important;height:100% !important;position:relative;background-color:'green';display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;}
.p1ejhblh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.f10tbhzf{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.m162qi2t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;}
.m1o4h3us{font-size:24px;font-weight:500;line-height:32px;}
.b1gxwkar{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;gap:8px;}@media (min-width:768px){.b1gxwkar{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}}
